import React from 'react';
import styled from 'styled-components';
import {GlobalFunctions} from '../../../GlobalFunctions'
import { up, down, between, only } from 'styled-breakpoints';


class Alert extends React.Component {
    constructor(props) {
        super()
        this.state = {
            
        }
        this.key = GlobalFunctions.randomString()
    }

    componentDidMount() {
     
    }

    render (){
        const {text} = this.props
        return (
            <Content key={"Alert"+this.key} className="Content content-fluid">
                <Text>{text}</Text>
            </Content>
                
        )
    }
}

export default Alert;

const Content = styled.div`
width:430px;
${down('md')}{
    width:calc(90vw - 50px);
}
`

const Text = styled.p`
text-align:center;
padding-top:50px;
padding-bottom:50px;
font-weight: 300;
`


