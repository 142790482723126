import {GlobalFunctions} from "../GlobalFunctions"
import {store} from '../store/configureStore'
import {GlobalFunctions as GF} from '../GlobalFunctions'

function JSONparse(data,key){
  data.forEach((d,index)=>{
    try{
      data[index][key] = JSON.parse(d[key])
    }catch(e){ data[index][key] = [] }
  })
  return data
}

export function getUsers(arg) {
  return (dispatch) => {
      
    GlobalFunctions.API_task({
        operation:'getUsers'
    }).then(data => 
      dispatch({
        type: 'JUST_SET_VALUE',
        payload:data,
        key:'USERS'
      })
    )
    
  }
}

export function getDB_Data(operation,tableName,JSONparseKey){
  return (dispatch) => {
    GF.API_task({
      operation
    }).then(data=>{
      
      if(Array.isArray(JSONparseKey)){
        
        JSONparseKey.forEach(key=>{
          if(key!=null)
            JSONparse(data,key)
        })
        
      }
      else
        if(JSONparseKey!=null)
          JSONparse(data,JSONparseKey)
      
      dispatch({
        type: 'JUST_SET_VALUE',
        key: tableName,
        payload:data
      })
    })
    
  }
}

export function getDB_Data_lazy(tableName,JSONparseKey){
  return (dispatch) => {
    GF.API_task({
      operation:'getDataFromTable',
      Table:tableName
    }).then(data=>{
      
      if(Array.isArray(JSONparseKey)){
        
        JSONparseKey.forEach(key=>{
          if(key!=null)
            JSONparse(data,key)
        })
        
      }
      else
        if(JSONparseKey!=null)
          JSONparse(data,JSONparseKey)
      
      dispatch({
        type: 'JUST_SET_VALUE',
        key: tableName,
        payload:data
      })
    })
    
  }
}


export function getVARS(arg) {
  return (dispatch) => {
      
    GlobalFunctions.API_task({
        operation:'getVARS'
    }).then(data => {
      let obj = {}
      data.forEach(d=>{
        if(!isNaN(d.VALUE))
          d.VALUE=d.VALUE*1
        obj[d.NAME] = d.VALUE
      })
      dispatch({
        type: 'JUST_SET_VALUE',
        payload:obj,
        key:'VARS'
      })
    })
    
  }
}

export function getCUSTOMERS(arg) {
  return (dispatch) => {
      
    GlobalFunctions.API_task({
      operation:'getCUSTOMERS'
    }).then(async data => {

      let BOOKINGS = [] //ожидание получения данных
      while(BOOKINGS.length<=0){
        BOOKINGS = store.getState().page.BOOKINGS
        await GlobalFunctions.WaitingTimer(10)
      }

      let ROOMS = [] //ожидание получения данных
      while(ROOMS.length<=0){
        ROOMS = store.getState().page.ROOMS
        await GlobalFunctions.WaitingTimer(10)
      }

      data.forEach((d,i)=>{
        data[i].BOOKINGS = []
        data[i].BOOKINGS = BOOKINGS.filter(b=>b.ID_USER == d.ID_USER)
        data[i].BOOKINGS.forEach((b,y)=>{
          data[i].BOOKINGS[y].ROOM = ROOMS.find(r=>r.ID == b.ID_ROOM)
        })
      })

      dispatch({
        type: 'JUST_SET_VALUE',
        payload:data,
        key:'USER_CUSTOMERS'
      })
    })
    
  }
}



