import React from 'react';
import { connect } from 'react-redux';
import { closePopUp } from '../../actions/PopUpActions';
import styled from 'styled-components';
import {GlobalFunctions as GF} from '../../GlobalFunctions'



class PopUp extends React.Component {
    constructor(props) {
        super()
        this.state = {
           visible:false
        }
        this.key = GF.randomString()
    }

    componentDidMount() {
       setTimeout(()=>{
           this.setState({visible:true})
           
       },1)
    }

    
    render (){
        return (<View>
            <Bg/>
            <Pop key={this.key} visible={this.state.visible}>
                <Close onClick={()=>this.props.closePopUp()}>х</Close>
               {this.props.children}
            </Pop>
        </View>)
    }
}

const Pop = styled.div`
    position: fixed;
    left: 50%;
    transition: .3s ease-in-out;
    top: ${props=>props.visible?"50%":"55%"};
    opacity: ${props=>props.visible?"1":"0"};
    height: auto;
    background: #fff;
    width: max-content;
    max-width:90vw;
    max-height:90vh;
    overflow:auto;
    border-radius: 5px;
    z-index: 2;
    box-shadow: 5px 20px 150px rgba(0,0,0,0.2);
    transform: translate(-50%,-50%);
    padding:15px;
    > .Content { 
        margin:20px;
     }; 
`
const Bg = styled.div`
position:fixed;
top:0px;
width:100vw;
height:100vh;
left:0px;
z-index:1;
background:rgba(0,0,0,0);
`

const View = styled.div`
position:fixed;
top:0px;
width:100vw;
height:100vh;
left:0px;
z-index:100;
background:rgba(0,0,0,0);
`

const Close = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    font-weight: 100;
    cursor: pointer;
    opacity: 0.8;
    transition: .5s;
    height: 20px;
    width: 20px;
    text-align: center;
    
    background: rgb(250, 250, 250);
    line-height: 19px;
    border-radius: 3px;
    box-shadow: 5px 2px 10px rgba(0,0,0,0);
    z-index:20;
    &:hover{
        opacity:1;
        background: rgb(250, 250, 250);
        box-shadow: 5px 2px 10px rgba(0,0,0,0.03);
    }
    &:active{
        font-size:9px
    }

`

const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp: () => dispatch(closePopUp()), 
    };
  };

const mapStateToProps = (store) => {
    
    return {
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(PopUp);