
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import { Label } from '../../components/moduls/Input';
import { withRouter } from 'react-router-dom' 




class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
        }
        this.input=[]
    }



    render (){
        const {page,history} = this.props
        return (
           <MainView>
                <div className='row'>
                    <div className='col-md-3'>
                        <Card onClick={()=>history.push('/roomes')}>
                            <h3>{page.ROOMS.length}</h3>
                            <p>Все номера</p>
                        </Card>
                    </div>
                    <div className='col-md-3'>
                        <Card onClick={()=>history.push('/roomes')}>
                            <h3>{page.ROOMS.filter(r=>r.ID_STATUS==1).length}</h3>
                            <p>Активные</p>
                        </Card>
                    </div>
                    <div className='col-md-3'>
                        <Card onClick={()=>history.push('/clients')}>
                            <h3>{page.USER_CUSTOMERS.length}</h3>
                            <p>Клиенты</p>
                        </Card>
                    </div>
                    <div className='col-md-3'>
                        <Card  onClick={()=>history.push('/booking')}>
                            <h3>{page.BOOKINGS.filter(b=>b.ID_STATUS!=4).length}</h3>
                            <p>Бронирования</p>
                        </Card>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-6'>
                        <Card >
                            <br/>
                            <a  href={global.URL_host+'book/'+page.HOTEL.GUID_KEY}><Label>{global.URL_host+'book/'+page.HOTEL.GUID_KEY}</Label></a>
                            <p>Ссылка на страницу бронирования для клиентов</p>
                        </Card>
                    </div>
               </div>
           </MainView>
        )
    }
}

const MainView = styled.div`

`


const Card = styled.div`
background:white;
border-radius:10px;
box-shadow:10px 10px 30px rgba(0,0,0,0.03);
padding:20px;
text-align:center;
& h3{
margin-bottom:0px;
margin-top:15px;
}
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        page:store.page
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Index));

