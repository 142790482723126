
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getDB_Data } from '../../../actions/PageActions';
import Table from '../../components/Table'
import Alert from '@mui/material/Alert';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import Input, { Label } from '../../components/moduls/Input'
import ComboBox from '../../components/moduls/ComboBox';
import Input_image from '../../components/Input_image';

import { withToast } from '../../components/Toasts';
import { up, down, between, only } from 'styled-breakpoints';
import POP_Rooms from '../Roomes/POP_Rooms';

const form_fields = {

    main : [
        {
            title:'ФИО',
            name:'FIO',
        },
        {
            title:'E-mail',
            name:'EMAIL',
        },
        {
            title:'Телефон',
            name:'PHONE',
        },
        {
            title:'Уровень',
            name:'LVL',
        }
    ]
}




class POP_Client extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ID_OBJECT:props.ID_OBJECT,
            Self:{}
        }
       
        this.isWait = false

        this.filesToUpload=[]
        this.filesToDelete=[]
        
    }

    componentDidMount(){
        if(this.props.ID_OBJECT!=null)
            this.getSelf()
    }

    getSelf(){
        try{
            
            let Self = this.props.USER_CUSTOMERS.find(data => data.ID_USER == this.props.ID_OBJECT)
            this.setState({Self})
        }catch(e){}
    }

   
    render (){
        const {Self} = this.state
        const {BOOKING_STATUSES,ID_OBJECT,data} = this.props
        
        return (
            <POP> 
                <div className='row'>
                    <div className='col-5'>
                    <Vline/>
                        <h4>О клиенте</h4>
                        <br/>
                        {form_fields.main.map(field => 
                            <p><b>{field.title}</b><br/>{Self[field.name]}</p>
                        )} 
                         <p><b>Баллы</b><br/>{GF.makeCoast(Self['BALS'])}</p>
                        <br/>
                        

                    </div>
                    <div className='col-7'>
                        <h4>История бронирований</h4>
                        {Self.BOOKINGS==null? null:
                            <ScrollView>
                                {Self.BOOKINGS.length==0?
                                    <Alert severity="warning">История бронирований не найдена</Alert>
                                    :
                                    Self.BOOKINGS.map(b=>
                                        b.ROOM==null?null:
                                        <View className='p-2 mb-2'>
                                            <b className='m-0' onClick={()=>
                                                this.props.openPopUp(<POP_Rooms ID_OBJECT={b.ROOM.ID}/>)}
                                            >{b.ROOM.TITLE}</b>
                                            <br/>
                                            <small className='m-0'>{GF.makeNormalDate(b.DATE_BOOK_START,'dd.mm.yyyy')} - {GF.makeNormalDate(b.DATE_BOOK_END,'dd.mm.yyyy')}</small>
                                            <br/>
                                            <b>{GF.makeCoast(b.BOOK_COAST)} руб</b>
                                            <State color={b.STATUS_COLOR}>{b.STATUS_TITLE}</State>
                                        </View>   
                                    ) 
                                }
                            </ScrollView>
                        }
                    </div>
                </div>
                

                
            </POP>
        )
    }
    
}

export const View = styled.div`
background:rgba(128, 128, 128, 0.1);
border-radius:5px;
`

export const State = styled.div`
background:#${props=>props.color};
color:white;
font-weight:600;
margin-top:10px;
border-radius:5px;
padding:10px;
`


export const Vline = styled.div`
background:rgba(128, 128, 128, 0.16);
height:100%;
width:1px;
float:right;
`

const POP = styled.div`
width:700px;
height:auto;
${down('md')}{
    width:calc(90vw - 50px);
}
`

export const ScrollView = styled.div`
max-height:70vh;
overflow-y:auto;
overflow-x:hidden;
`



const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        BOOKING_STATUSES : store.page.BOOKING_STATUSES,
        BOOKINGS: store.page.BOOKINGS,
        USER_CUSTOMERS: store.page.USER_CUSTOMERS
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(POP_Client)

