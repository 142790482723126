import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getUsers } from '../../../actions/PageActions';
import { GlobalFunctions } from '../../../GlobalFunctions';
import Input from '../../components/moduls/Input'
import { withRouter } from "react-router"
import { withCookies } from 'react-cookie'
import {withToast} from '../../components/Toasts'

import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import Table from '../../components/Table';
import POP_Client from './POP_Client';


const Rows = [

  {
      title:'ФИО',
      key:'FIO',
  },
  {
      title:'Почта',
      key:'EMAIL',
  },
  {
      title:'Телефон',
      key:'PHONE',
  },
  {
      title:'Уровень',
      key:'LVL',
  },
  {
      title:'Баллы',
      key:'BALS',
  }
]

class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
          errorMSG:null,
          succes:false,
          filter:null,
          Data:[]
        }
        this.input={
          dataWindow:{},
          main:{}
        }
        this.checkBoxs={}
        
    }


   
    
    PreDelete(){
      let promises = []
      let somethingDoing = false

        Object.keys(this.checkBoxs).forEach((key,index)=>{
          if(this.checkBoxs[key].checked){
            somethingDoing=true
            this.checkBoxs[key].checked=false
            promises.push(this.Delete(key))
            delete(this.checkBoxs[key])
          }
        })

        if(!somethingDoing) return

        Promise.allSettled(promises)
        .then(()=>{
          this.props.getUsers()
          this.props.addToast('Удалено', { appearance: 'success',autoDismiss:true });
        })
      
    }

    Delete(ID){
      return new Promise((resolve,reject)=>{
        let data = {
          operation:'deleteSome',
          Table:'USERS',
          ID
        }
        
        GlobalFunctions.API_task(data).then(res=>{
         
         
          setTimeout(()=>resolve(),1)
          
        }).catch(()=>reject()) 
      })
      
    }

    changeBlock(ID){
      return new Promise((resolve,reject)=>{
        let data = {
          operation:'changeBlockUser',
          ID
        }
        
        GlobalFunctions.API_task(data).then(res=>{
          
          setTimeout(()=>resolve(),1)
        }).catch(()=>reject()) 
      })
    }

    Block(){
      let somethingDoing = false
      let promises = []
      Object.keys(this.checkBoxs).forEach((key,index)=>{
          if(this.checkBoxs[key].checked){
            somethingDoing = true
            this.checkBoxs[key].checked=false
            promises.push(this.changeBlock(key))
          }
      })
      
      if(!somethingDoing) return

      Promise.allSettled(promises)
        .then(()=>{
          this.props.getUsers()
          this.props.addToast('Значение обновлено', { appearance: 'success',autoDismiss:true });
        })
    }

  

    render (){
      const {filter,Data} = this.state
        return (
        <MainView>
          <div>
            

            <Search placeholder='Поиск' className='form-control float-start d-block ' onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}/>
          </div>
          
          <br/>
          <Table
                className='mt-3'
                rows={Rows}
                canCheck={false}
                checkBoxs={this.checkBoxs}
                data={this.props.USER_CUSTOMERS.filter(a=> filter==null || a.FIO.toUpperCase().indexOf(filter)!=-1 )}
                itemOnClick={({data,index})=>{
                    this.props.openPopUp(<POP_Client ID_OBJECT={data.ID_USER}/>)
                }}
                rowOnClick={({data,index})=>{
                    debugger
                }}
                renderItem={({data,index})=>
                  Rows.map(r=>r.key=='BALS'?<td>{GlobalFunctions.makeCoast(data[r.key])}</td> : <td>{data[r.key]}</td>)
                }/>

          
        </MainView>
        )
    }
}

const MainView = styled.div`

`



const Search = styled.input`
width:400px;
`

const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getUsers:(arg)=>dispatch(getUsers(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
        USER_CUSTOMERS:store.page.USER_CUSTOMERS
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withToast(Index))

