import React, { Component } from 'react';

import styled from 'styled-components';
import { connect } from 'react-redux';
import { loadUserData_FromStorage,loginIn } from '../actions/UserAct';
import { getVARS,getUsers, getDB_Data,getDB_Data_lazy,getCUSTOMERS} from '../actions/PageActions';
import Login from "./Login"
import { ToastProvider } from 'react-toast-notifications'
import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";

import { withCookies } from 'react-cookie';
import Main from './Main';
import CientBooking from '../View/pages/CientBooking'
import {GlobalFunctions as GF} from "../GlobalFunctions"
import "../global"

class App extends Component {
  constructor(props) {
    super(props)

    const { cookies } = props;

    global.afterSustemLoad = () => {
      //тут описывается то, что будет запущено при старте системы
      this.props.loginIn()
    }

    global.afterLoginned = () => {
      //тут описывается то, что будет запущено после авторизации
      try{
        this.props.getVARS()
        
        this.props.getDB_Data('getROOM_STATUSES','ROOM_STATUSES')
        this.props.getDB_Data('getBOOKING_STATUSES','BOOKING_STATUSES')
        this.props.getDB_Data('getROOMS', 'ROOMS', 'IMAGES_URL')
        this.props.getDB_Data('getBOOKINGS', 'BOOKINGS')
        this.props.getCUSTOMERS()
        this.props.getDB_Data('getSelfHotel', 'HOTEL')
        
        
      }catch(e){}
    }

    global.startShedul = () => {
      //тут описываются все события, которые должны быть запущены после успешной авторизации
    }

    props.loadUserData_FromStorage()
    



  }


  

  render() {
    const { user,PopUps} = this.props
    let content = null
  
   
    if (user.isLogined == null) {
      content =  null
    } else if (user.isLogined)
      content =  (
        <div key="rootView"> 
          <Router key="mainRouter" >
            <Switch>
              
              <Route path='/book/:GUID_KEY'>
                <CientBooking/>
              </Route>

              <Route path='/book/'>
                <CientBooking/>
              </Route>

              <Route path='/'>
                <Main/>
              </Route>

              
            </Switch>
            
          </Router>
        </div>
      )
    else 
    content = (
        <Router key="mainRouter" >
            <Switch>
              <Route path='/book/:GUID_KEY'>
                <CientBooking/>
              </Route>
              <Route path='/book/'>
                <CientBooking/>
              </Route>

              <Route path='/'>
                <Login />
              </Route>

            </Switch>
            
          </Router>
    )
    
    return (
      <ToastProvider>
        {PopUps.length>0?<BlurBg visible={true}/>:<BlurBg visible={false}/>}

        {PopUps.map(popUp=>{
          return popUp
        })}
     
        {content}
      </ToastProvider>
      
    )

  }
}

const BlurBg = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    background: rgba(0,0,0,0.3);
    opacity:${props=>props.visible?1:0};
    transition: .5s;
    width: 100vw;
    z-index:${props=>props.visible?100:-999};;
`


const mapDispatchToProps = (dispatch) => {
  return {
    loadUserData_FromStorage: (arg) => dispatch(loadUserData_FromStorage(arg)), 
    loginIn: (arg) => dispatch(loginIn(arg)), 
    getVARS: (arg) => dispatch(getVARS(arg)), 
    getUsers:(arg) => dispatch(getUsers(arg)), 
    getCUSTOMERS:(arg) => dispatch(getCUSTOMERS(arg)),
    getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 
    getDB_Data_lazy:(tableName,JSONparseKey) => dispatch(getDB_Data_lazy(tableName,JSONparseKey)), 
  };
};


const mapStateToProps = (store) => {
  return {
    user: store.user,
    PopUps: store.popups.windows
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(App));

