
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import { withRouter } from 'react-router-dom';
import { Label } from '../../components/moduls/Input';
import bg1_jpg from '../../../design/main_bg/bg1.jpg'
import { GlobalFunctions as GF } from '../../../GlobalFunctions';
import POP_ClientBook from './POP_ClientBook';
import { up, down, between, only } from 'styled-breakpoints';
import ResponseView from '../../components/moduls/ResponceView';



class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
           errorMSG:null,
           GUID_KEY: props.match.params.GUID_KEY,
           HOTEL:{},
           HOTEL_ROOMS:[],
           openedRooms:[],
        }
        this.input=[]


       if(this.state.GUID_KEY!=null){
           this.getHotel()
       }
    }

    getHotel(){
        GF.API_task({
            operation:'getHotel',
            block:'OutOfAuth',
            GUID_KEY:this.state.GUID_KEY
        }).then(res=>{
            if(res[0]==null)
                this.setState({HOTEL:false})
            else
                this.setState({HOTEL:res[0]})
        })

        this.getHotelRooms()
    }

    getHotelRooms(){

        let LIVING_COUNT = null
        let dateStart = null
        let dateEnd = null
        try{
             LIVING_COUNT = this.input.LIVING_COUNT.value*1
             dateStart = this.input.dateStart.value
             dateEnd = this.input.dateEnd.value
        }catch(e){}

        if(LIVING_COUNT==null || LIVING_COUNT=='') LIVING_COUNT = 1
        if(dateStart==null || dateStart=='') dateStart = GF.makeNormalDate(new Date(),'yyyy-mm-dd')
        if(dateEnd==null || dateEnd=='') dateEnd = GF.makeNormalDate(new Date(),'yyyy-mm-dd')
        

        
      
        this.setState({openedRooms:[]})

       
            new Promise((resolve,reject)=>{
                GF.API_task({
                    operation:'getHotelRooms',
                    block:'OutOfAuth',
                    GUID_KEY:this.state.GUID_KEY,
                    LIVING_COUNT,
                    dateStart,
                    dateEnd
                }).then(res=>{
                    res.forEach((d,i)=>{
                        res[i].IMAGES_URL = JSON.parse(res[i].IMAGES_URL)
                    })
                    this.setState({HOTEL_ROOMS:res})
                    setTimeout(()=>{
                        resolve(res)
                    },1)
                }).catch(()=>reject())
            })
            
        
    

       
    }

    getPrice(room){
        let dateStart = null
        let dateEnd = null
        try{
             dateStart = new Date(this.input.dateStart.value)
             
        }catch(e){}
        try{
            dateEnd = new Date(this.input.dateEnd.value)
       }catch(e){}

        if(!(dateStart.getDay()>-1)) dateStart = new Date()
        if(!(dateEnd.getDay()>-1)) dateEnd = new Date()
        let days = Math.ceil(Math.abs(new Date(dateStart).getTime() - new Date(dateEnd).getTime()) / (1000 * 3600 * 24))  
        if(days==0) days = 1

        return 'итого '+GF.makeCoast(room.COAST * days) + ' руб за ' + days + GF.okonchanie(days,' ночь',' ночи',' ночей')
        
            
    }

    render (){
        const {page} = this.props
        const {GUID_KEY,HOTEL,HOTEL_ROOMS,openedRooms} = this.state

        if(GUID_KEY==null){
            return <p className='text-center'>Неверная ссылка для бронирования номера</p>
        }

        if(HOTEL===false){
            return <p className='text-center'>404 Отель не найден</p>
        }

        return (
           <MainView>
                <TopMenu className='bg-light p-2'>
                    <h4 className='text-center m-0'>Система бронирования номеров</h4>
                    <p className='text-center m-0'>by ITTS</p>
                </TopMenu>
                <Img src={bg1_jpg}/>

                <FilterBar>
                    <ResponseView style={{display:'table-cell'}}>
                            <FilterBlock>
                            
                                <p className='m-0'>{HOTEL.TITLE}</p>
                                
                            </FilterBlock>
                            <Line/>
                    </ResponseView>
                    

                    <FilterBlock>
                        <Label>Дата заезда</Label>
                        <input onChange={()=>this.getHotelRooms()} ref={ref=>this.input.dateStart=ref} className='form-control' type='date'/>
                    </FilterBlock>

                    <Line/>
                   
                    <FilterBlock>
                        <Label>Дата выезда</Label>
                        <input onChange={()=>this.getHotelRooms()} ref={ref=>this.input.dateEnd=ref} className='form-control' type='date'/>
                    </FilterBlock>

                    
                    <Line/>
                   
                    <FilterBlock>
                        <Label>Количество персон</Label>
                        <input onChange={()=>this.getHotelRooms()} ref={ref=>this.input.LIVING_COUNT=ref} placeholder='1' className='form-control' type='number' min='1' max='50'/>
                    </FilterBlock>

                </FilterBar>

                <ScrollView>
                    {HOTEL_ROOMS.map((room,i)=>{
                        let isOpen = openedRooms.findIndex(r => r==room.ID)
                        let price = this.getPrice(room)
                        let nightCoast = GF.makeCoast(room.COAST)
                    return[
                      <Room.View>
                        <Room.ImgView>
                            
                            <Room.Img src={global.URL_host+'img/'+room.IMAGES_URL[0]}/>
                        </Room.ImgView>
                        <Room.TextView>
                        <Label>для {room.LIVING_COUNT} {GF.okonchanie(room.LIVING_COUNT,'гостя','гостей','гостей')}</Label>
                            <h4 className='mb-0'>{room.TITLE}</h4>
                            
                            

                            <Room.Coast>{nightCoast} руб / ночь</Room.Coast>
                            <Label className='mb-3'>{price}</Label>
                            <p className='text'>{isOpen==-1?room.DESCR_SM:room.DESCR_LG}</p>
                            {room.IS_BOOKED!=1?<ButBron className='btn btn-sm btn-primary' onClick={()=>{
                                let dateStart = null
                                let dateEnd = null
                                let LIVING_COUNT = null
                                try{
                                     LIVING_COUNT = this.input.LIVING_COUNT.value*1
                                     dateStart = this.input.dateStart.value
                                     dateEnd = this.input.dateEnd.value
                                }catch(e){}
                        
                                if(LIVING_COUNT==null || LIVING_COUNT=='') LIVING_COUNT = 1
                                if(dateStart==null || dateStart=='') dateStart = GF.makeNormalDate(new Date(),'yyyy-mm-dd')
                                if(dateEnd==null || dateEnd=='') dateEnd = GF.makeNormalDate(new Date(),'yyyy-mm-dd')
                            
                                this.props.openPopUp(<POP_ClientBook ID_OBJECT={room.ID} data={{
                                    room,
                                    ID_HOTEL:HOTEL.ID,
                                    dateStart,
                                    dateEnd,
                                    LIVING_COUNT,
                                    nightCoast,
                                    price
                                }}
                                callBack={()=>this.getHotelRooms()}/>)
                            }} >Забронировать</ButBron>: 
                            <ButBron className='btn btn-sm btn-danger'>Занято</ButBron>}
                            
                            {isOpen!=-1 || room.DESCR_LG==null || room.DESCR_LG==''?null:
                                <div className='btn btn-sm btn-light right' onClick={()=>{
                                    let newData = openedRooms.splice(0)
                                    if(isOpen==-1)
                                        newData.push(room.ID)
                                    else
                                        newData.splice(isOpen,1)
                                    
                                    this.setState({openedRooms:newData})
                                }}>Подробнее</div>
                            }
                        </Room.TextView>
                        
                    </Room.View>,
                    i<HOTEL_ROOMS.length-1?<hr/>:null
                    ]})}
                </ScrollView>
           </MainView>
        )
    }
}

const ButBron = styled.div`
${down('md')}{
    left: 0px;
    top: 205px;
    bottom: unset !important;
    width: 150px;
}
`

const Room = {
    View:styled.div`
    position:relative;
    ${down('md')}{
        min-height: 235px;
    }
    `,
    Coast:styled.p`
    margin-top:5px;
    margin-bottom:5px;
    font-weight:bold;
    `,
    Status:styled.p`
    position: absolute;
    margin: 10px;
    background: rgb(200, 85, 73);
    padding: 3px 10px;
    font-size: 13px;

    border-radius: 10px;
    text-transform: uppercase;
    color: rgb(71, 14, 14);
    `,
    ImgView:styled.div`
    display:table-cell;
    `,
    Img:styled.img`
    height:200px;
    border-radius:15px;
    width:200px;
    object-fit:cover;
    ${down('sm')}{
        width:150px;
        height:200px;
    }
    `,
    TextView:styled.div`
    
    display:table-cell;
    vertical-align: top;
    padding-left:20px;

    & .btn{
        position:absolute;
        bottom:0px;
    }

    & .right{
        right:0px;
    }

    & .text{
        line-height:16px;
        font-size:14px;
        margin-bottom:50px;
    }

    ${down('sm')}{
        & h4{
            font-size:14px;
        }
        & .text{
            line-height:14px;
            font-size:12px;
            margin-bottom:20px;
        }
    }
    `,
}


const ScrollView = styled.div`
background:white;
width:650px;
z-index:1;
margin:auto;
margin-top:190px;
margin-bottom:10vh;
border-radius:20px;
padding:20px;
min-height:100px;

${down('sm')}{
    width:calc(100vw - 20px);
   
    padding:15px;
}
`







const Img = styled.img`
position:fixed;
top:0px;
left:0px;
z-index:-1;
width:100vw;
object-fit:cover;
height:100vh;
`



const MainView = styled.div`


`

const TopMenu = styled.div`
position:fixed;
top:0px;
left:0px;
z-index:10;
width:100vw;
`
const Line = styled.div`
height:100%;
width:1px;
display:table-cell;
background:rgb(220,220,220);
`

const FilterBar = styled.div`
z-index:10;
position:fixed;
    left:calc(50vw - 325px);
    top:90px;
    background:rgba(255,255,255,0.90);
    border-radius:20px;
    width:650px;
    box-shadow:10px 10px 30px rgba(0,0,0,0.05);
    padding:15px 55px;
    backdrop-filter:blur(10px);

    & input.form-control{
        background-color:rgba(255,255,255,0.9) !important;
        border-color:rgba(255,255,255,1) !important;
    }

    ${down('sm')}{
        width:calc(100vw - 20px);
        left:calc(10px);
        padding:12px 5px;
    }
`
const FilterBlock = styled.div`
   display:table-cell;
   padding-left:10px;
   vertical-align: middle;
   padding-right:10px;
   & h4{
       margin:0px;
   }

    
`




const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    }
}


const mapStateToProps = (store) => {
   
    return {
        page:store.page
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Index))

