
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getDB_Data } from '../../../actions/PageActions';
import Table from '../../components/Table'
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import Input, { Label } from '../../components/moduls/Input'
import ComboBox from '../../components/moduls/ComboBox';
import Input_image from '../../components/Input_image';
import Alert from '../../PopUp/Views/Alert';
import { withToast } from '../../components/Toasts';
import { up, down, between, only } from 'styled-breakpoints';
import {Card,CardActions,Typography,CardActionArea,CardContent,Grid} from '@mui/material';

const form_fields = {

    main : [
        {
            title:'ФИО',
            name:'FIO',
            placeholder:'Проживающий'
        },
        {
            title:'E-mail',
            name:'EMAIL',
            placeholder:'ex@mail.ru'
        },
        {
            title:'Телефон',
            name:'PHONE',
            placeholder:'+7'
        },
    ]
}




class POP_ClientBook extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ID_OBJECT:props.ID_OBJECT,
            Self:{
                CLIENT:{}
            }
        }
        this.input={
            main:{}
        }

        this.isWait = false

        this.filesToUpload=[]
        this.filesToDelete=[]
        
    }

   

    Delete(){
        GF.API_task({
            operation:'deleteElement',
            Table:'ROOMES',
            ID:this.props.ID_OBJECT
        }).then(res=>{
           
            this.props.addToast('Удалено', { appearance: 'success',autoDismiss:true });
            this.props.closePopUp()
        })
    }

    clientMakeBook(){
        if(this.isWait) return
        if(!GF.checkReqvireds(this.input.main)) return
        
        let data = {}
        Object.keys(this.input.main).forEach(key=>{
            try{
                let val = this.input.main[key].value
                data[key] = val.length == 0 ? null : val
            }catch(e){}
        })
        
        this.isWait = true
        let reqv = {
            operation:'clientMakeBook',
            block:'OutOfAuth',
            data,
            ID_HOTEL: this.props.data.ID_HOTEL,
            dateStart:this.props.data.dateStart,
            dateEnd:this.props.data.dateEnd,
            ID_ROOM:this.props.data.room.ID
        }

        data.PHONE = data.PHONE.replace('+','')
        
        GF.API_task(reqv).then(res=>{
            this.isWait = false
            this.props.closePopUp()
            this.props.callBack()
            this.props.openPopUp(<Alert text='Номер забронирован. Менеждер свяжется с Вами в ближайшее время для подтверждения'/>)
            
        }).catch(e=>{debugger})
    }

    render (){
        const {Self} = this.state
        const {BOOKING_STATUSES,ID_OBJECT,data} = this.props
        
        return (
            <POP> 
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                    <h4>Бронирование номера</h4>
                
                        {form_fields.main.map(input => 
                            <Input    
                            {...input}
                            Ref={this.input.main}/>
                        )} 
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <ClientInfo>
                       <br/>

                        <Label>Номер</Label>
                        <p className='mb-2'>{data.room.TITLE}</p>

                        <Label>Дата заезда</Label>
                        <p className='mb-2'>{GF.makeNormalDate(data.dateStart,'dd Q yyyy')} года </p>

                        <Label>Дата выезда</Label>
                        <p className='mb-2'>{GF.makeNormalDate(data.dateEnd,'dd Q yyyy')} года</p>

                        <Label>Количество проживающих</Label>
                        <p className='mb-2'>{data.LIVING_COUNT} {GF.okonchanie(data.LIVING_COUNT,'человек','человека','человек')}</p>

                        <Label>Стоимость</Label>
                        <p>{data.nightCoast} руб / ночь</p>

                        
                        <p>{data.price}</p>
                    </ClientInfo>
                    </Grid>
                </Grid>
                
                
                
               


                
                
                

                
                <div className='btn btn-sm btn-primary float-end' onClick={()=>
                    this.clientMakeBook()}
                >Оставить заявку</div>

                

                
            </POP>
        )
    }
}

const POP = styled.div`
width:700px;
height:auto;
${down('md')}{
    width:calc(90vw - 50px);
}
`
const ClientInfo = styled.div`
margin-bottom: 20px;
& p{
    margin-bottom: 0px;
}
& small{
    display: block;
    margin: 0;
}


${down('md')}{
    & p{
        font-size:13px;
    }
}
`




const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        BOOKING_STATUSES : store.page.BOOKING_STATUSES,
        BOOKINGS: store.page.BOOKINGS,
        USER_CUSTOMERS: store.page.USER_CUSTOMERS
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(POP_ClientBook)

