
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getDB_Data } from '../../../actions/PageActions';
import Table from '../../components/Table'
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import Input from '../../components/moduls/Input'
import ComboBox from '../../components/moduls/ComboBox';
import Input_image from '../../components/Input_image';
import Alert from '../../PopUp/Views/Alert';
import { withToast } from '../../components/Toasts';
import POP_Client, { ScrollView, State, View, Vline } from '../Clients/POP_Client';
import POP_Book from '../Booking/POP_Book';

import {Alert as AlertUI,Grid,AlertTitle} from '@mui/material';


import {Skeleton} from '@mui/material';

const form_fields = {
    main : [
        {
            title:'Название',
            placeholder:'50 символов',
            name:'TITLE',
           
        },
        {
            title:'Цена за ночь',
            placeholder:'Стоимость за 1 ночь',
            name:'COAST',
            type:'number',
            min:1,
            max:90000,
        },
        {
            title:'Кол-во людей в номере',
            placeholder:'1-50',
            type:'number',
            min:1,
            max:50,
            name:'LIVING_COUNT'
        },
        {
            title:'Кол-во кроватей двуспальных',
            placeholder:'1-5',
            type:'number',
            min:1,
            max:5,
            name:'COUNT_BEDS_2'
        },
        {
            title:'Кол-во кроватей одноместных',
            placeholder:'1-10',
            type:'number',
            min:1,
            max:10,
            name:'COUNT_BEDS_1'
        },
        {
            title:'Кол-во диванов',
            placeholder:'1-5',
            type:'number',
            min:1,
            max:5,
            name:'COUNT_SOFA'
        },

        {
            title:'Кол-во столов',
            placeholder:'1-5',
            type:'number',
            min:1,
            max:5,
            name:'COUNT_TABLES'
        }
    ]
}




class POP_Rooms extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ID_OBJECT:props.ID_OBJECT,
            Self:{},
            loading:true
        }
        this.input={
            main:{}
        }

        this.filesToUpload=[]
        this.filesToDelete=[]
        
    }

    componentDidMount(){
        setTimeout(()=>this.setState({loading:false}),1000)
        if(this.props.ID_OBJECT!=null)
            this.getSelf()
    }

    getSelf(){
        
        let Self = this.props.ROOMS.find(data => data.ID == this.state.ID_OBJECT)
        Self.BOOKINGS = this.props.BOOKINGS.filter(b=>b.ID_ROOM == this.state.ID_OBJECT)
        Self.BOOKINGS.forEach((b,i)=>{
            Self.BOOKINGS[i].USER_CUSTOMER = this.props.USER_CUSTOMERS.find(c=>c.ID_USER==b.ID_USER)
        })
        
        this.setState({Self})
       
        setTimeout(()=>{
           
            Object.keys(this.input.main).forEach(key =>{
                if(this.input.main[key].type=='date' || this.input.main[key].type =='datetime-local'){
                    let val = GF.isStringDateTime(Self[key]) ? 
                        Self[key].replace(' ','T'):
                        Self[key]

                    this.input.main[key].value = val
                }else
                    this.input.main[key].value = Self[key]
                
            })
        },1)
    }

    Delete(){
        GF.API_task({
            operation:'deleteElement',
            Table:'ROOMES',
            ID:this.props.ID_OBJECT
        }).then(res=>{
           
            this.props.addToast('Удалено', { appearance: 'success',autoDismiss:true });
            this.props.closePopUp()
        })
    }

    Save(){
        if(!GF.checkReqvireds(this.input.main)) return
        if(this.filesToUpload.length==0 && this.props.ID_OBJECT==null) {
            this.props.openPopUp(<Alert text="Нужно выбрать хотябы одно изображение для загрузки"/>)
            return
        }
        let data = {}
        Object.keys(this.input.main).forEach(key=>{
            try{
                let val = this.input.main[key].value
                data[key] = val.length == 0 ? null : val
            }catch(e){}
        })

       
        let reqv = {
            operation:'createUpdateROOM',
            data
        }
        if(this.props.ID_OBJECT != null)
            reqv.ID = this.props.ID_OBJECT

        GF.API_task(reqv).then(res=>{
            let promises = []
        
            
            if(this.filesToUpload.length>0)
                promises.push(GF.API_files({
                    operation:'setIMAGE',
                    Table:'ROOMS',
                    ID:res.ID
                },this.filesToUpload))

            if(this.filesToDelete.length>0)
                promises.push(GF.API_task({
                    operation:'deleteIMAGE',
                    ID:this.props.ID_OBJECT,
                    Table:'ROOMS',
                    filesToDelete:JSON.stringify(this.filesToDelete)
                }))
            Promise.all(promises).then(()=>{
                this.props.getDB_Data('getROOMS', 'ROOMS', 'IMAGES_URL')
            })
            this.props.closePopUp()
        }).catch(e=>{debugger})
    }

    render (){
        const {Self,loading} = this.state
        const {ROOM_STATUSES,ID_OBJECT} = this.props
        
        return (
            <POP> 
                <div className='row'>
                    <div className='col-6'>
                    
                        <h4>Номер {ID_OBJECT}</h4>
                        <div className='row'>
                            <div className='col-6'>
                                <ComboBox
                                    name='ID_STATUS'
                                    title='Статус'
                                    Ref={this.input.main}>
                                    {ROOM_STATUSES.map(s=>
                                        <option value={s.ID} selected={s.ID==1}>{s.TITLE}</option>
                                    )}
                                </ComboBox>
                            </div>
                        </div>
                        

                        
                        
                    {form_fields.main.map(input => 
                            <Input    
                            {...input}
                            Ref={this.input.main}/>
                        )} 

                        

                        <div className='mb-2' style={{height:140}}>
                        <Input_image multiple={true} value={Self.IMAGES_URL} onChange={(filesToUpload,filesToDelete)=>{
                            
                            this.filesToUpload=[...this.filesToUpload,...filesToUpload]
                            this.filesToDelete=[...this.filesToDelete,...filesToDelete]
                            
                        }}/>
                        </div>

                        
                        <div className='btn btn-sm btn-light ' onClick={()=>
                            this.Save()}
                        >Сохранить </div>

                        {ID_OBJECT!=null?
                            <div className='btn btn-sm btn-danger float-end' onClick={()=>
                                this.Delete()}
                            >Удалить</div>
                        :null}
                    
                    
                    
                    </div>
                    <div className='col-6'>
                        <h4>История бронирований</h4>
                        {loading?[
                            <Skeleton variant="rectangular" className='mb-2'  height={142} />,
                            <Skeleton variant="rectangular" className='mb-2'  height={142} />,
                            <Skeleton variant="rectangular" className='mb-2'  height={142} />,
                        ]:
                        Self.BOOKINGS==null? null:
                            <ScrollView>
                                {Self.BOOKINGS.length==0 ?
                                    <AlertUI severity="warning">История бронирований не найдена</AlertUI>
                                    :
                                    Self.BOOKINGS.map(b=>
                                        b.ROOM==null?null:
                                        <View className='p-2 mb-2' onClick={()=>
                                            this.props.openPopUp(<POP_Book ID_OBJECT={b.ID}/>)}>
                                            <b className='m-0' onClick={(e)=>{
                                                e.stopPropagation()
                                                this.props.openPopUp(<POP_Client ID_OBJECT={b.USER_CUSTOMER.ID_USER}/>)}}
                                            >{b.USER_CUSTOMER.FIO}</b>
                                            <br/>
                                            <small className='m-0'>{GF.makeNormalDate(b.DATE_BOOK_START,'dd.mm.yyyy')} - {GF.makeNormalDate(b.DATE_BOOK_END,'dd.mm.yyyy')}</small>
                                            <br/>
                                            <b>{GF.makeCoast(b.BOOK_COAST)} руб</b>
                                            <State color={b.STATUS_COLOR}>{b.STATUS_TITLE}</State>
                                        </View>   
                                    ) 
                                }
                            </ScrollView>
                        }
                    </div>
                </div>
                

                
            </POP>
        )
    }
}

const POP = styled.div`
width:800px;
height:auto;
`




const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        ROOM_STATUSES : store.page.ROOM_STATUSES,
        USER_CUSTOMERS:store.page.USER_CUSTOMERS,
        ROOMS: store.page.ROOMS,
        BOOKINGS: store.page.BOOKINGS,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withToast(POP_Rooms));

