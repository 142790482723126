
  const initialState = {
    HOTEL:{},//Инфо о своем отеле
    ROOM_STATUSES:[],
    ROOMS:[],
    VARS:[],//статические переменные
    USER_CUSTOMERS:[],

    BOOKINGS:[],
    BOOKING_STATUSES:[]

  };
  
  export function pageReducer(state = initialState, action) {
    switch (action.type) {


      case 'JUST_SET_VALUE':
        let temp_state={}
        Object.assign(temp_state,state)
        temp_state[action.key] = action.payload
        
        return temp_state
      
      
      default:
        return state;
    }
  }