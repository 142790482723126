import React from 'react';
import firebase from "firebase/app";
import styled from 'styled-components';
import { openPopUp } from "../actions/PopUpActions"
import { loadUserData_FromStorage } from "../actions/UserAct"
import { withCookies } from 'react-cookie';
import Alert from './PopUp/Views/Alert'
import { connect } from 'react-redux';
import {GlobalFunctions} from '../GlobalFunctions'
import Input from './components/moduls/Input'
import BackImg from '../design/bg.jpg'

class Login extends React.Component {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
        }
        this.input=[]
    }

    

    componentDidMount() {
       
    }

    passwordSendToMail(){
        if(!GlobalFunctions.checkReqvireds(this.input,["PASSWORD"])) return

     
        GlobalFunctions.API_task({
            operation:"passwordSendToMail",
            LOGIN:this.input.LOGIN.value
        }).then(res=>{
            if(res=="ok"){
                this.props.openPopUp(<Alert text="Восстановленный пароль выслан на почту пользователя"/>)
            }
        })
    }

    signIN(){
        this.setState({errorMSG:null})
        if(!GlobalFunctions.checkReqvireds(this.input)) return

        GlobalFunctions.API_task({
            operation:"loginIn",
            block:'Auth', 
            password:this.input.PASSWORD.value,
            login:this.input.LOGIN.value
        }).then(res=>{
            if(res== null){
               
                this.setState({errorMSG:"Неверный логин или пароль"})
                return
            }
            const { cookies } = this.props
            cookies.set("login", res.LOGIN, { path: '/' })
            cookies.set("password", res.PASSWORD, { path: '/' })
            cookies.set("id", res.ID, { path: '/' })
           

            this.props.loadUserData_FromStorage()
        }).catch((error)=>{
            
            this.setState({errorMSG:error.message})
        })
    }

    render (){
        const {} = this.props.page
        
        return (
            <Page className="Page Login bg-light">
                <DarkBg/>
                <div ref={ref => (this.recaptcha = ref)}></div>
                <div className="row">
                    <div className="col-lg-4 col-md-3"/>
                    <div className="col-lg-4 col-md-6">
                      
                           <View>
                            
                                <h5>Вход в систему</h5>
                               
                                <p>Панель управления номерами</p>
                                <Input Ref={this.input}
                                    title='Логин'
                                    name='LOGIN'
                                    placeholder=''/>
                                <Input Ref={this.input}
                                    title='Пароль'
                                    name='PASSWORD'
                                    placeholder=''/>
                                <a onClick={()=>this.signIN()} className="btn btn-light w-25 btn-sm mt-2">Вход</a>
                               
                            </View>
                        {this.state.errorMSG!=null?
                            <div className="alert alert-danger mt-3 text-center" role="alert">
                                {this.state.errorMSG}
                            </div>
                        :null}
                        
                        
                    </div>
                    <div className="col-lg-4 col-md-3"/>
                </div>
            </Page>
        )
    }
}

const View = styled.div`
background:white;
padding:20px !important;
border-radius:20px;
margin-top:50px;
position:relative;
z-index:10;
`


const mapDispatchToProps = (dispatch) => {
    return {
      openPopUp: (content) => dispatch(openPopUp(content)),
      loadUserData_FromStorage : (content) => dispatch(loadUserData_FromStorage(content)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
        page:store.page
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(Login));

const Page = styled.div`
    background:url('${BackImg}');
    object-fit: cover;
    background-position: center;
    background-size: cover;
overflow: hidden;
min-height:100vh;
`

const DarkBg = styled.div`
position:fixed;
background:rgba(0,0,0,0);
top:0;
left:0px;
width:100vw;
height:100vh;
`
