
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import POP_Rooms from '../Roomes/POP_Rooms';

import POP_Book from './POP_Book';




class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
           Dates: this.generateDates()
        }
        this.input=[]

        this.roomsViews=[]
        
    }

    generateDates(){
        var days = [
            'ВС',
            'ПН',
            'ВТ',
            'СР',
            'ЧТ',
            'ПТ',
            'СБ'
          ]
          
        

        let result = []
        let daysCount = -60
        let DateNow = new Date()
        DateNow.setDate(DateNow.getDate() + daysCount)
        while(daysCount < 60){
            result.push({
                date:GF.makeNormalDate(DateNow,'dd.mm.yyyy'),
                day:GF.makeNormalDate(DateNow,'dd'),
                month:GF.makeNormalDate(DateNow,'T'),
                year:GF.makeNormalDate(DateNow,'yyyy'),
                weekDay: days[ DateNow.getDay() ]
            })
            DateNow.setDate(DateNow.getDate() + 1)
            daysCount++
            
        }
        return result
    }

    

    componentDidMount() {
        this.MainView.scrollLeft=50*50
    }

   

    renderDates(){
        const {Dates} = this.state

        const CurrentDate = GF.makeNormalDate(new Date(),'dd.mm.yyyy')

        let result =[]
        let chotNechet = false
        let curMonth = null
        let curYear = null
        Dates.map(dt => {
           
            if(dt.weekDay=='ПН')
                chotNechet=!chotNechet
            result.push(
                <div className={chotNechet?'bg-light':''}>
                    {curMonth==dt.month && curYear==dt.year?null:<h4>{
                        (curMonth==dt.month?'':dt.month) + ' ' + (curYear==dt.year?'':dt.year)
                    }</h4>}
                    <h3 className={CurrentDate==dt.date?'current':''}>{dt.day}</h3>
                    <p className={CurrentDate==dt.date?'current':''}>{dt.weekDay}</p>
                    <line/>
                </div>
            )
            curMonth=dt.month
            curYear=dt.year
        })
        return result
    }

    DateLine(room){
        const {Dates} = this.state
        let Bookings = this.props.BOOKINGS.filter(bk => bk.ID_ROOM == room.ID)

        let result =[]
       

        Dates.map(dt => {
            let dayResult = null
            let CurrentBook = null

            CurrentBook = Bookings.find(bk => GF.makeNormalDate(bk.DATE_BOOK_START,'dd.mm.yyyy') == dt.date)
            if(CurrentBook!=null)
                dayResult = <Booked onClick={()=>this.props.openPopUp(<POP_Book ID_OBJECT={CurrentBook.ID}/>)} title={CurrentBook.STATUS_TITLE} days={CurrentBook.BOOK_DAYS} color = {CurrentBook.STATUS_COLOR}/>
            
            

            result.push(
                <DayResult>
                       {dayResult}     
                </DayResult>
            )
        })
        
        return result
    }


    render (){
        const {ROOMS,BOOKING_STATUSES} = this.props
        const {Dates} = this.state

        return (
           <MainView ref={ref=>this.MainView=ref}>
              <RoomsView>
                {ROOMS.map(room=>
                    <p ref={ref=>this.roomsViews[room.ID]=ref} onClick={()=>this.props.openPopUp(<POP_Rooms ID_OBJECT={room.ID}/>)}>{room.TITLE}</p>
                )}
              </RoomsView>
              <BookingsView>
                <DateView>
                    {this.renderDates()}
                </DateView>    
                {ROOMS.map(room=>
                    <BookLine onMouseEnter={()=>{
                        this.roomsViews[room.ID].classList.add('hover')
                    }} onMouseLeave={()=>{
                        this.roomsViews[room.ID].classList.remove('hover')
                    }}>
                        {this.DateLine(room)}
                    </BookLine>
                )}
                
              </BookingsView>
                <Legend>
                    {BOOKING_STATUSES.map(b=>
                        <LegendView>
                        <LegendColor color={b.COLOR}/>
                            <LegendTitle>{b.TITLE}</LegendTitle>
                        </LegendView>
                    )}
                </Legend>
           </MainView>
        )
    }
}
const LegendView = styled.div`
margin-bottom:5px;

`

const LegendColor = styled.div`
heigth:10px;
width:25px;

border-radius:5px;
display:table-cell;
background:#${props=>props.color};
`
const LegendTitle = styled.p`
padding-left:5px;
display:table-cell;
`
const Legend = styled.div`
position:fixed;
bottom:20px;
border-radius:10px;
height:auto;
width:auto;
padding:20px;
background:white;
right:20px;
box-shadow:5px 10px 30px rgba(0,0,0,0.1);
z-index:10;
`

const MainView = styled.div`
overflow: auto;
margin-left: -20px;
margin-top: -20px;
width: calc(100% + 40px);
height: calc(100% + 40px);
`

const RoomsView = styled.div`
position: absolute;
height: calc(100% - 50px);

background: rgba(255, 255, 255, 0.67);
backdrop-filter: blur(20px);
padding: 0px 20px;
z-index: 1;
padding-top: 75px;

& p{
    border-radius: 5px;
    background: rgba(159,159,159,0.09);
    padding: 4px 8px;
    font-size: 12px;
    height: 40px;
    line-height: 32px;
    cursor: pointer;
    margin-bottom: 10px;
    -webkit-transition: .3s;
    transition: .3s;
}
& p:hover,
& p.hover{
    background: rgba(255, 255, 255, 1);
}
`

const BookingsView = styled.div`

`

const DayResult = styled.div`
width: 50px !important;
height: 50px !important;
display: table-cell;

`

const Booked = styled.div`
position: absolute;
border-radius: 10px;
background: #${props=>props.color};
height: 40px;
margin-top: 5px;
margin-left: 27.5px;
width: ${props=>props.days * 50 - 2.5}px;
transition:.3s;
cursor:pinter;
min-width: 45px;
opacity:0.8;
&:hover{
    opacity:1;
}
`

const BookLine = styled.div`
width: max-content;
position: relative;
z-index: 0;
`

const DateView = styled.div`
width: max-content;
position: relative;
z-index: 0;
    & div{
        width: 50px !important;
        text-align:center;
        display: table-cell;
        margin-bottom: 0px;
    }
    & p{
        margin-bottom: 3px;
    }
    & h3{
        margin-top: 15px;
        margin-bottom: -6px;
    }
    & h4{
        position: absolute;
        font-size: 14px;
    }
    & .current{
        color: rgb(85, 126, 209);
    }
    & line{
        position: absolute;
        width: 1px;
        height: calc(100vh - 50px - 40px);
        background: rgba(0,0,0,.04);
        margin-left: -25px;
        top: 20px;
    }
`



const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        ROOMS:store.page.ROOMS,
        BOOKING_STATUSES:store.page.BOOKING_STATUSES,
        BOOKINGS: store.page.BOOKINGS
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Index);

