
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getDB_Data,getCUSTOMERS } from '../../../actions/PageActions';
import Table from '../../components/Table'
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import Input, { Label } from '../../components/moduls/Input'
import ComboBox from '../../components/moduls/ComboBox';
import Input_image from '../../components/Input_image';

import { withToast } from '../../components/Toasts';
import POP_Client from '../Clients/POP_Client';


import {Alert,Card,CardActions,Typography,CardActionArea,CardContent,Grid} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const form_fields = {
    main : [
        {
            title:'Дата заезда',
            name:'DATE_BOOK_START',
            type:'date'
        },
        {
            title:'Дата выезда',
            name:'DATE_BOOK_END',
            type:'date'
           
        },
    ]
}




class POP_Book extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ID_OBJECT:props.ID_OBJECT,
            Self:{
                CLIENT:{
                    BOOKINGS:[null]
                },
            }
        }
        this.input={
            main:{}
        }

        this.filesToUpload=[]
        this.filesToDelete=[]
        
    }

    componentDidMount(){
        if(this.props.ID_OBJECT!=null)
            this.getSelf()
    }

    getSelf(){
        try{
            let Self = this.props.BOOKINGS.find(data => data.ID == this.state.ID_OBJECT)
            
            Self.CLIENT = this.props.USER_CUSTOMERS.find(cl => cl.ID_USER == Self.ID_USER)

            this.setState({Self})
        
            setTimeout(()=>{
                try{
                Object.keys(this.input.main).forEach(key =>{
                    if(this.input.main[key].type=='date' || this.input.main[key].type =='datetime-local'){
                        let val = GF.isStringDateTime(Self[key]) ? 
                            Self[key].replace(' ','T'):
                            Self[key]

                        this.input.main[key].value = val
                    }else
                        this.input.main[key].value = Self[key]
                    
                })
            }catch(e){}
            },1)
        }catch(e){}
    }

    Delete(){
        GF.API_task({
            operation:'deleteElement',
            Table:'ROOMES',
            ID:this.props.ID_OBJECT
        }).then(res=>{
           
            this.props.addToast('Удалено', { appearance: 'success',autoDismiss:true });
            this.props.closePopUp()
        })
    }

    Save(arg={}){
        if(!GF.checkReqvireds(this.input.main)) return
        
        let data = {}
        Object.keys(this.input.main).forEach(key=>{
            try{
                let val = this.input.main[key].value
                data[key] = val.length == 0 ? null : val
                
            }catch(e){}
        })

        
        if(this.state.Self.ID_STATUS!=5 && this.input.main.ID_STATUS.value*1==5 && !this.state.isPayQv){// был не оплачен а поставили, что оплачен
            this.setState({isPayQv:true})
            return
        }

        if(arg.isPaySet===true){
            GF.API_task({
                operation:'makeOplata',
                ID_BOOKING:this.props.ID_OBJECT
            }).then(res=>{
                
            })
        }
       
        let reqv = {
            operation:'updateBOOK',
            data,
            ID: this.props.ID_OBJECT
        }

        GF.API_task(reqv).then(res=>{
            this.props.getDB_Data('getBOOKINGS', 'BOOKINGS')
            this.props.getCUSTOMERS()
            setTimeout(()=>{
                try{
                this.getSelf()
                }catch(e){}
            },100)
            
            setTimeout(()=>{
                try{
                this.getSelf()
                }catch(e){}
            },500)

            setTimeout(()=>{
                try{
                this.getSelf()
                }catch(e){}
            },2000)
            
            this.props.closePopUp()
        }).catch(e=>{debugger})
    }

    render (){
        const {Self,isPayQv} = this.state
        const {BOOKING_STATUSES,ID_OBJECT} = this.props
        
        const countOfBooks = Self.CLIENT.BOOKINGS == null ||  Self.CLIENT.BOOKINGS.length==0 || Self.CLIENT.BOOKINGS[0]==null ? 0 :Self.CLIENT.BOOKINGS.filter(b=>b.ID_STATUS==5).length

        const countOfCancels = Self.CLIENT.BOOKINGS == null ||  Self.CLIENT.BOOKINGS.length==0 || Self.CLIENT.BOOKINGS[0]==null ? 0 :Self.CLIENT.BOOKINGS.filter(b=>b.ID_STATUS==4).length
        
        return ([
            <POP> 
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <h4 className='mb-0'>Бронирование номера</h4>
                        <Label className='mb-3'>от {GF.makeNormalDate(Self.DATE_CREATE,'dd Q yyyy H:M')}</Label>
                        <div className='row'>
                            <div className='col-6'>
                                <ComboBox
                                    name='ID_STATUS'
                                    title='Статус'
                                    Ref={this.input.main}>
                                    {BOOKING_STATUSES.map(s=>
                                        <option value={s.ID} selected={s.ID==1}>{s.TITLE}</option>
                                    )}
                                </ComboBox>
                            </div>
                        </div>
                        <p className='mb-0 d-block'>Стоимость проживания: {GF.makeCoast(Self.BOOK_COAST>0?Self.BOOK_COAST:Self.DAY_COAST)} руб</p>
                        <small className='mt-0 d-block'>{GF.makeCoast(Self.DAY_COAST)} руб / ночь</small>
                        <small className='mt-0 mb-3 d-block'>{Self.BOOK_DAYS>0?
                            Self.BOOK_DAYS + GF.okonchanie(Self.BOOK_DAYS,' ночь',' ночи',' ночей')
                            :
                            '1 ночь'
                        }</small>
                        <Grid container spacing={2}>
                        {form_fields.main.map(input => 
                            <Grid item xs={6}>
                                <Input    
                                {...input}
                                Ref={this.input.main}/>
                            </Grid>
                        )} 
                        </Grid>
                        
                            
                            <div className='btn btn-sm btn-light ' onClick={()=>
                                this.Save()}
                            >Сохранить </div>

                            {ID_OBJECT!=null && Self.ID_STATUS == 4?
                                <div className='btn btn-sm btn-danger float-end' onClick={()=>
                                    this.Delete()}
                                >Удалить</div>
                            :null}
                    </Grid>
                    <Grid item xs={6}>
                    <ClientInfo>
                    
                   
                    <Card variant="outlined" className='mt-2'>
                        <CardContent>
                            {countOfBooks==0?
                                <Alert severity="info">Бронирует впервые</Alert>
                                : Self.CLIENT.BOOKINGS.length==1?
                                <Alert severity="success">Уже бронировал 1 раз</Alert>
                                :
                                <Alert severity="warning">Постоянный клиент</Alert>
                            }
                            {countOfCancels==0?null:
                                <Alert severity="error" className='mt-1'>Были отмены</Alert>
                            }
                        
                        <Typography mt={2} gutterBottom variant="h6" component="div">
                        {Self.CLIENT.FIO}
                            </Typography>
                            
                            <Typography variant="body2" color="text.secondary">
                            <p>{Self.CLIENT.EMAIL}</p>
                            
                            <p>{GF.phoneFormatter(Self.CLIENT.PHONE)}</p>
                        </Typography>
                       
                </CardContent>
                        <CardActions>
                            <Button size="small" onClick={()=>this.props.openPopUp(<POP_Client ID_OBJECT={Self.CLIENT.ID_USER}/>)}> Подробнее</Button>
                        </CardActions>
                    </Card>
                    
                    
                    
                </ClientInfo>
                    </Grid>
                
                </Grid>
            </POP>,
            <Dialog open={isPayQv}>
            <DialogTitle>
            Оплата бронирования
            </DialogTitle>
            <DialogContent>
            <DialogContentText>
                После назначения бронированию статуса "Оплачено", клиенту будут начислены баллы за оплату. Не рекомендуется ставить данный статус, если Вы не получили средства от клиента за бронирвоание 
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={()=>{
                this.setState({isPayQv:false})
            }}>Отмена</Button>
            <Button autoFocus onClick={()=>{
                this.Save({isPaySet:true})
                this.setState({isPayQv:false})
            }}>
                Продолжить
            </Button>
            </DialogActions>
        </Dialog>
        ])
    }
}

const POP = styled.div`
width:800px;
height:auto;
`
const ClientInfo = styled.div`
margin-bottom: 20px;
& p{
    margin-bottom: 0px;
}
& small{
    display: block;
margin: 0;
}
`




const mapDispatchToProps = (dispatch) => {
    return {
        getCUSTOMERS:(arg)=>dispatch(getCUSTOMERS(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        getDB_Data:(operation,tableName,JSONparseKey) => dispatch(getDB_Data(operation,tableName,JSONparseKey)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        BOOKING_STATUSES : store.page.BOOKING_STATUSES,
        BOOKINGS: store.page.BOOKINGS,
        USER_CUSTOMERS: store.page.USER_CUSTOMERS
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withToast(POP_Book));

