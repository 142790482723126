import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import { GlobalFunctions as GF} from '../../../GlobalFunctions';
import POP_Rooms from './POP_Rooms';

import {Alert,Grid,AlertTitle} from '@mui/material';

const form_fields = {
  main : [
      {
          title:'Кол-во людей в номере',
          name:'LIVING_COUNT'
      },
      {
          title:'Кол-во кроватей двуспальных',
          name:'COUNT_BEDS_2'
      },
      {
          title:'Кол-во кроватей одноместных',
          name:'COUNT_BEDS_1'
      },
      {
          title:'Кол-во диванов',
          name:'COUNT_SOFA'
      },

      {
          title:'Кол-во столов',
          name:'COUNT_TABLES'
      }
  ]
}


class Index extends React.Component {
  constructor(props) {
      super()
      this.state = {
       
       Filter:null
      } 
  }

  

  componentDidMount(){
    
  }


  render (){
    const {ROOMS,ROOM_STATUSES,BOOKINGS} = this.props
    const {TYPE,Filter} = this.state

    return (
      <MainView>
        <div>
          
          <Btn className='btn btn-sm btn-light me-2 float-start' onClick={()=>{
            this.props.openPopUp(<POP_Rooms/>)
          }}>
              Создать
          </Btn>

          <Search placeholder='Поиск' className='form-control float-start d-block ' onKeyUp={(e)=>this.setState({Filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}/>
        </div>
        <br/>
        
        <div className='row mt-3'>
          {ROOMS.map(room=> {
            if(Filter!=null && room.TITLE.toUpperCase().indexOf(Filter)==-1) return null
            
            let STATUS = ROOM_STATUSES.find(st=> st.ID == room.ID_STATUS )

            return(
              <div className='col-md-10 col-lg-7   mb-3'>
                <Card  onClick={()=>this.props.openPopUp(<POP_Rooms ID_OBJECT={room.ID}/>)}>
                  
                  {STATUS==null?null:
                    <Status COLOR={STATUS.COLOR}>{STATUS.TITLE}</Status>
                  }
                  <h3>{room.TITLE}</h3>
                  <small>{GF.makeCoast(room.COAST)} руб / ночь</small>
                  <CardLine>
                  {form_fields.main.map(fild=>
                    <Ind>
                      <h4>{room[fild.name]}</h4>
                      <p>{fild.title}</p> 
                    </Ind>
                  )}
                  </CardLine>
                  
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Alert  severity="info"> 
                        Бронирования: <b>{BOOKINGS.filter(b=>b.ID_ROOM==room.ID).length}</b>
                      </Alert>
                    </Grid>
                    <Grid item xs={6}>
                      <Alert  severity="warning"> 
                        Оплаты: <b>{BOOKINGS.filter(b=>b.ID_ROOM==room.ID && b.ID_STATUS==5).length}</b>
                      </Alert>
                    </Grid>
                  </Grid>
                 
                 
                 
                  
                  
                </Card>
              </div>
            )
        })}
        </div>

      </MainView>
      )
  } 
}

const CardLine = styled.div`
width: 100%;
display: table;
margin-top:20px;
`

const Ind = styled.div`
display: table-cell;
width:100px;
& h4{
  font-size: 18px;
  text-align: center;
  background: rgb(250,250,250);
  width: 40px;
  height: 40px;
  border-radius: 10px;
  line-height: 40px;
  margin: auto; 
}
& p{
  font-size: 12px;
  line-height: 10px;
  text-align: center;
}
`

const St = styled.small`
font-size:10px;
background:${props=>props.COLOR==null?'rgba(0,0,0,0.1)':props.COLOR};
color:white;
border-radius: 5px;
padding: 0px 10px;
width: max-content;
`

const Status = styled.div`
font-size:10px;
float:right;
background:${props=>props.COLOR==null?'rgba(0,0,0,0.1)':props.COLOR};
color:white;
border-radius: 5px;
padding: 0px 10px;
margin-right: -10px;
margin-top: -10px;
`

const Card = styled.div`

width:100%;
padding:20px;
background:white;
box-shadow:10px 10px 30px rgba(0,0,0,0.05);
border-radius:10px;
cursor:pointer;
transition:.3s;
&:hover{
  transform:scale(1.02);
}
& h3{
  font-size:16px;
  line-height:16px;
  width:calc(100% - 60px);
}
& p{
  font-size:13px;
  line-height:14px;
}
& small{
  display:block;
  font-size:9px;
  line-height:12px;

}
`

const MainView = styled.div`

`
const Search = styled.input`
width:300px;
`
const Btn = styled.div`

`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
      ROOMS : store.page.ROOMS,
      ROOM_STATUSES : store.page.ROOM_STATUSES,
      BOOKINGS : store.page.BOOKINGS,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Index)

