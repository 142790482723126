import React from 'react';
import styled from 'styled-components';
import { GlobalFunctions } from '../../../GlobalFunctions';
import { up, down, between, only } from 'styled-breakpoints';

export default class ResponseView extends React.Component {
    constructor(props) {
        super()
        this.state = {
        }
       
    }

 
    render (){
        
        return (
            <View {...this.props}>
                {this.props.children}
            </View>
        )
    }
}

const View = styled.div`
${down('md')}{
    display:none !important;
}
`
